import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'
import {Grid, TextField } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem';
import contact from '../../img/contact2.jpg'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

// import location from '../../img/map.png'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}


export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isValidated: false,
      title:'Contact Form Submission',
      userTitle: '',
      contactOption: '',
      slectedTime: '',
      ageGroup:'',
      checkPreferedTime: false
    }
  }

  componentDidMount() {
    // var poptl = new TimelineMax({
    //   delay:1
    // });
    // poptl.add("begin");
    // poptl.to(".contact-wrap",0.5,{opacity:1});
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleDateChange = (date) => {
    this.setState({ slectedTime: date })
  }

  handleCheckTime = () => {
    this.setState({
      checkPreferedTime: !this.state.checkPreferedTime
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  };

  render() {
    const contactOptions = [
      {
        value: 'Enrol',
        label: 'Enrol',
      },
      {
        value: 'Book a trial lesson',
        label: 'Book a trial lesson',
      }
    ];

    const titles = [
      {
        value: 'Mr',
        label: 'Mr',
      },
      {
        value: 'Ms',
        label: 'Ms',
      }
    ];

    const ageGroupes = [
      {
        value: 'Ages 5-7',
        label: 'Ages 5-7',
      },
      {
        value: 'Ages 8-9',
        label: 'Ages 8-9',
      },
      {
        value: 'Ages 10-11',
        label: 'Ages 10-11',
      },
      {
        value: 'Ages 12 and above',
        label: 'Ages 12 and above',
      }
    ];

    return (
      <Layout isProgramTypeSelected= {true}>
        <section className="section-contact">

          <Grid container
            direction="row"
            justify="center"
            alignItems="center"
          >
             <Grid item xs={0} sm={0} md={6} >
              <img src={contact} className="contact-cover-img" alt="contact us" />
             </Grid>

            <Grid item xs={12} sm={12} md={6} >
              <div className="contact-wrap">
                <div className="flex justify-between mb-block">
                  <h3 className="has-text-weight-semibold is-size-2 is-size-3-mobile is-size-2-tablet is-size-2-widescreen">
                    Get in touch with us
                  </h3>   
                </div>
                <form
                name="contact"
                method="post"
                action="/contact/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
                >

                  <Grid container
                    spacing={3}
                  >
                    <Grid item xs={12} sm={12} md={12} >
                      <TextField
                        id="standard-select-option"
                        select
                        margin="normal"
                        fullWidth
                        required
                        value={this.state.contactOption}
                        onChange={this.handleChange}
                        label="Please select an option"
                        name="contactOption"
                        
                      >
                        {contactOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12} sm={12} md={3} >
                      <TextField
                        id="standard-select-title"
                        select
                        margin="normal"
                        fullWidth
                        required
                        value={this.state.userTitle}
                        onChange={this.handleChange}
                        label="Title"
                        className="mt--1"
                        name="userTitle"
                      >
                        {titles.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9} >

                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="submitterName"
                        label="Name"
                        name="submitterName"
                        onChange={this.handleChange}
                        className="mt--1"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} >
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="mobile"
                        label="Mobile No"
                        name="mobile"
                        onChange={this.handleChange}
                        className="mt--1"
                        type="number"
                      />
                    </Grid>
                    {/* <Grid item xs={0} sm={0} md={1} >
  
                    </Grid> */}
                    <Grid item xs={2} sm={2} md={1} >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.checkPreferedTime}
                            onChange={this.handleCheckTime}
                            name={"Checked Prefered Time : " + this.state.checkPreferedTime}
                            color="primary"
                            value={this.state.checkPreferedTime}
                          />
                        }
                        label=""
                      />
                    </Grid>

                    <Grid item xs={10} sm={10} md={5} >
                      
                      <TextField
                        margin="normal"
                        fullWidth
                        type="time"
                        id="preferedTime"
                        label={!this.state.checkPreferedTime?("No Prefered Time"):("Select Prefered Time")}
                        name="preferedTime"
                        defaultValue="09:30"
                        className="mt--1"
                        onChange={this.handleChange}
                        disabled={this.state.checkPreferedTime?(false):(true)}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={8} >
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="studentName"
                        label="Name of Child"
                        name="studentName"
                        className="mt--1"
                        onChange={this.handleChange}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} >
                      <TextField
                        id="standard-select-age"
                        select
                        margin="normal"
                        fullWidth
                        required
                        value={this.state.age}
                        onChange={this.handleChange}
                        label="Age Group"
                        className="mt--1"
                        name="ageGroup"
                      >
                        {ageGroupes.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} >
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        type="email"
                        id="submitterEmail"
                        label="Email"
                        name="submitterEmail"
                        onChange={this.handleChange}
                        className="mt--1"
                      />
                    </Grid>

                  </Grid>

                  <Grid item xs={12} sm={12} md={12} >
                    <br />
                    <button type="submit" variant="contained" color="primary" className="btn btn-primary btn-fullwidth">
                      Submit
                  </button>
                  </Grid>
                </form>
                <br />
                {/* <Divider light className="faq-divider" /> */}
                {/* <div className="contact-wrap-info mb-block">
                  <div>
                    <h2>Contact Us On:</h2>
                    <h4 className="contact-wrap-number">0777 768 769</h4>
                  </div>
                  <div className="mb-mt-1">
                    <h2>Classes At:</h2>
                    <h4 className="contact-wrap-address">Nuga Rosmead,</h4>
                    <h4 className="contact-wrap-address">52, Rosmead place,</h4>
                    <h4 className="contact-wrap-address">Colombo 7.</h4>
                  </div>
                </div> */}
              </div>
            </Grid>
          </Grid>

 

        </section>
      </Layout>
    )
  }
}
